import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import Modal from "react-modal"; // Importing Modal
import { QRCodeCanvas } from "qrcode.react"; // Import QRCodeCanvas for QR Code generation
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root'); // To prevent screen readers from being trapped inside the modal

const Cart = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal
  const [paymentStatus, setPaymentStatus] = useState(null); // State to track payment status
  const [showPaymentStatus, setShowPaymentStatus] = useState(false); // State to show payment status after timeout
  const navigate = useNavigate(); // Initialize useNavigate
  // Calculate total amount
  useEffect(() => {
    let price = 0;
    products.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [products]);

  // Set shipping charge based on total amount
  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(30);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt > 401) {
      setShippingCharge(20);
    }
  }, [totalAmt]);

    // Function to generate a random 10-digit number
    const generateRandomPhoneNumber = () => {
      return Math.floor(Math.random() * 9000000000) + 1000000000; // Generates a 10-digit number
    };
  
    // Generate random `pa` value
    const randomPhoneNumber = generateRandomPhoneNumber();
  const qrString = `upi://pay?pa=9899142405@hdfcbank&pn=hashbug&tn=${randomPhoneNumber}&am=${totalAmt}&url=`;

  // Function to open modal and simulate payment
  const openModal = () => {
    setModalIsOpen(true);
    setPaymentStatus(null);
    setShowPaymentStatus(false);
    
    // Simulate payment status after 3 minutes
    setTimeout(() => {
      const isSuccess = Math.random() > 0.5; // 50% chance of success
      if (isSuccess) {
        setPaymentStatus("Payment ");
        navigate('/payment-success');
      } else {
        setPaymentStatus("Payment ");
        navigate('/payment-success');
      }
      setShowPaymentStatus(true); // Show the payment status message
    }, 180000); // 3 minutes delay
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here, e.g., sending data to an API
    console.log('Form submitted'); // Placeholder for actual submission logic
  };
  // Function to close modal
  const closeModal = () => {
    setModalIsOpen(false);
    setPaymentStatus(null); // Reset payment status when modal is closed
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Cart" />
      {products.length > 0 ? (
        <div className="pb-20">
          {/* Table Headers */}
          <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
            <h2 className="col-span-2">Product</h2>
            <h2>Price</h2>
            <h2>Quantity</h2>
            <h2>Sub Total</h2>
          </div>

          {/* Cart Items */}
          <div className="mt-5">
            {products.map((item) => (
              <div key={item._id}>
                <ItemCard item={item} />
              </div>
            ))}
          </div>

          {/* Reset Cart Button */}
          <button
            onClick={() => dispatch(resetCart())}
            className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300"
          >
            Reset cart
          </button>

          {/* Cart Summary */}
          <div className="flex flex-col mdl:flex-row justify-between border py-4 px-4 items-center gap-2 mdl:gap-0">
            <p className="text-lg font-semibold">Update Cart</p>
          </div>

          <div className="max-w-7xl gap-4 flex justify-end mt-4">
            <div className="w-96 flex flex-col gap-4">
              <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Subtotal
                  <span className="font-semibold tracking-wide font-titleFont">
                  ₹{totalAmt}
                  </span>
                </p>
                {/* <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Shipping Charge
                  <span className="font-semibold tracking-wide font-titleFont">
                    ${shippingCharge}
                  </span>
                </p> */}
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                  Total
                  <span className="font-bold tracking-wide text-lg font-titleFont">
                  ₹{totalAmt }
                  </span>
                </p>
              </div>

              {/* Proceed to Checkout with Modal */}
              <div className="flex justify-end">
                <button
                  onClick={openModal} // Open modal on click
                  className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>

          {/* Modal for QR Code */}
          <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="QR Code Modal"
  className="flex justify-center items-center fixed inset-0 pt-20 z-50 bg-black bg-opacity-70 overflow-y-auto"
>
  <div className="bg-white p-4 md:p-8 rounded-lg shadow-lg flex flex-col md:flex-row items-start space-y-8 md:space-y-0 md:space-x-12 max-w-5xl w-full">
    {/* Address Form */}
    <div className="w-full md:w-1/2 overflow-y-auto max-h-[70vh] md:pr-4  px-4">
      <h2 className="text-2xl md:pt-0 xl:pt-0 sm:pt-20 font-semibold mb-6">Address Details</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="mb-4 ">
          <label>Name *</label>
          <input type="text" name="name" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>Email *</label>
          <input type="text" name="email" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>Telephone *</label>
          <input type="text" name="telephone" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>Address *</label>
          <input type="text" name="address" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>State *</label>
          <input type="text" name="state" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>City *</label>
          <input type="text" name="city" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>Pincode *</label>
          <input type="text" name="pincode" required className="border p-2 w-full" />
        </div>
        <div className="mb-4">
          <label>Additional Details</label>
          <input type="text" name="additionalDetails" className="border p-2 w-full" />
        </div>
        <button    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md">
          Submit
        </button>
      </form>
    </div>

    {/* QR Code Section */}
    <div className="w-full md:w-1/2 flex flex-col items-center">
      <h2 className="text-2xl font-semibold mb-6">Scan to Pay</h2>
      <QRCodeCanvas value={qrString} className="mb-4" size={200} />

      {showPaymentStatus && (
        <div className="mt-4">
          <h3
            className={`font-semibold ${
              paymentStatus === 'Payment Successful' ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {paymentStatus}
          </h3>
        </div>
      )}

      <button
        onClick={closeModal}
        className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md"
      >
        Close
      </button>
    </div>
  </div>
</Modal>

        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Cart feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              books, electronics, videos, etc. and make it happy.
            </p>
            <Link to="/shop">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Continue Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Cart;
