import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  a12,a16,a11,a17,w1,w16,w19,w21,kurta11,kurta12,kurta13,kurta14,kurta54,kurta53,kurta52,kurta51,kurta44,kurta43,kurta42,kurta41,kurta33,kurta32,kurta31,kurta22
  , kurta23, kurta21
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={[kurta11,kurta12,kurta13,kurta14]}
            
            productName= "Women's Outfit"
            price= "4535.00"
    category= "Kurti"
    badge={true}
    des= "An ideal choice for the modern woman, this outfit combines style with functionality."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img={[kurta21,kurta22, kurta23]}
            productName="Women's Kurti"
            price="5250.00"
            category= "Kurti"
    badge={true}
    des= "A stunning accessory that combines delicate design with floral-inspired elegance."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={[kurta31,kurta32,kurta33]}
            productName="Printed Kurti"
            price="4700.00"
            category= "Kurti"
    badge={true}
    des= "Perfect for both day and evening wear, this outfit delivers a polished and professional look."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={[kurta41,kurta42,kurta43,kurta44]}
            productName="Women's Kurti"
            price="6220.00"
            category= "Kurti"
    badge={true}
    des= "A timeless outfit designed to bring out your confidence with its elegant and bold style."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img={[kurta51,kurta52,kurta53,kurta54]}
            productName="Women's Kurti"
            price="4850.00"
             category= "Kurti"
             badge={true}
    des= "An exquisite accessory that adds a sophisticated floral element to your look."
          />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
