import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const  Cancellation= () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    // Check if location.state and location.state.data exist
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation(""); // or handle the fallback behavior if data is missing
    }
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Cancellation Policy" prevLocation={prevLocation} />
      <div className='p-5'>
        <center><b>Cancellation & Refund Policy</b></center>
        <hr />
        <p className='px-5'>
            <b>1. Window for Cancellations:</b>
            <ul>
                <li> Orders may be canceled by customers within 24 hours of placement.</li>
                <li>
                Refunds might not be available if the order has already been shipped or processed.
                </li>
            </ul>
            <b>2. Cancellation Procedure:</b>
            <ul>
                <li> To cancel an order, consumers can use the cancellation link in their account or email our customer support team at gauravsingh.bluehorse@gmail.com.</li>
                <li>
                Provide the order number and the explanation for the cancellation.  
                </li>
            </ul>
            <b>
            3. Refund Procedure:
            </b>
            <ul>
                <li>
                Within 30 days of the order's cancellation, the original payment method will receive a complete refund.
                </li>
                <li>
                Customers must instead initiate a return if the order has already arrived.
                </li>
            </ul>
            <b>
            4. Changes to Orders:
            </b>
            <ul>
                <li>
                Customers may request changes to their order like shipping address and item quantity before it has been processed. Please contact us as soon as possible.

                </li>
            </ul>
            <b>
          
5. Exceptions:

            </b>
            <ul>
                <li>
               
We maintain the right to reject cancellation requests under certain conditions, such as fraudulent orders.

                </li>
            </ul>
            <b>
          
            6. Customer Support:

            </b>
            <ul>
                <li>
               
                Contact our support team at gauravsingh.bluehorse@gmail.com/+91 872190 80262 if you have any questions or need any help with cancellations.

                </li>
            </ul>
        </p>
      
    </div>
    </div>
  );
};

export default Cancellation;
