import React from 'react'

const Success = () => {
  return (
    <div className='container d-flex justify-content-center' style={{padding:'150px'}}>

        <h1 style={{fontSize:'28px'}}>Thank you for your purchase.</h1>
        <h5>We will keep you updated on your payment status and order status via email.</h5>
      
    </div>
  )
}

export default Success
