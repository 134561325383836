import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,

  w1,
  w2,
  w3,
  w4,w5,w6, w7, w8,w9,w10,w13,w12, w14, w15,w16,w17,w18,w19,w20,w21,w22,w23,w24,w25,w26,w27,
  a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20,a21,a22,
  kurta64,kurta63,kurta62,kurta61,kurta54,kurta53,kurta52,kurta51,kurta44,kurta43,kurta42,kurta41,kurta33,kurta32,kurta31,kurta22
  , kurta23, kurta21,kurta14,kurta13,kurta12,kurta11,handbag12,handbag11,earing23,earing22,earing21,earing11,earing13,dress63,dress62,dress61
  ,dress53,dress52,dress51,dress43,dress42,dress41,dress33,dress32,dress31,dress23,dress22,dress21,dress13,dress12,dress11,earing64,earing63,earing61,earing62,earing53,earing52,earing51,earing42,earing41,earing33,earing32,earing31
  ,wallet63,wallet62,wallet61,wallet53,wallet52,wallet51,wallet44,wallet43,wallet41,wallet42,wallet33,wallet32,wallet31,wallet23,wallet22,wallet21,wallet13,wallet12,wallet11,shadi52,shadi51,
  shadi42,shadi41,shadi32,shadi31,shadi22,shadi21,shadi12,shadi11,sandal64,sandal63,sandal62,sandal61,sandal53,sandal52,sandal51,sandal44,sandal43,sandal42,sandal41,sandal33,sandal32,sandal31,sandal23,sandal22,sandal21,sandal12,sandal13,sandal11,neckless64,
  neckless34,neckless33,neckless32,neckless31,neckless24,neckless23,neckless22,neckless21,neckless13,neckless12,neckless11,handbag63,handbag62,handbag61,handbag53,handbag52,handbag51,handbag45,handbag44,handbag43,handbag42,handbag33,handbag32,
  handbag31,handbag24,neckless63,neckless62,neckless61,neckless54,neckless53,neckless52,neckless51,neckless44,neckless43,neckless41,neckless42,handbag23,handbag22,handbag21,handbag13
, coset64,coset63,coset62,coset61,coset53,coset52,coset51,coset44,coset43,coset42,coset41,coset34,coset33,coset32,coset31,coset23,coset24,coset22,coset21,coset13,coset12,coset11,
hoodie64,hoodie63,hoodie62,hoodie61,hoodie54,hoodie53,hoodie52,hoodie51,hoodie44,hoodie43,hoodie42,hoodie41,hoodie33,hoodie32,hoodie31,hoodie23,hoodie22,hoodie21,hoodie13,hoodie12,hoodie11,
jeans64,jeans63,jeans62,jeans61,jeans54,jeans53,jeans51,jeans52,jeans44,jeans43,jeans42,jeans41,jeans34,jeans32,jeans31,jeans23,jeans24,jeans22,jeans21,jeans14,jeans13,jeans12,jeans11,
jeans33, sun11,sun12,sun13,sun14,sun21,sun22,sun23,sun31,sun32,sun33,sun41,sun42,sun43,sun44,sun51,sun52,sun53,sun54,sun61,sun62,sun63,sun64,
ss11,ss12,ss13,ss14,ss21,ss22,ss23,ss24,ss31,ss32,ss33,ss41,ss42,ss43,ss51,ss52,ss53,ss54,ss61,ss62,ss63,ss64,tp11,tp12,tp13,tp21,tp22,tp23,tp24,tp31,tp32,tp33,tp41,tp42,tp43,tp44,tp51,tp52,tp53,tp54,tp61,tp62,tp63,tp64,watch11,watch12,watch13,watch21,watch22,watch23,watch24,watch31,watch32,watch33,
watch41,watch42,watch43,watch44,watch51,watch52,watch53,watch54,watch61,watch62,watch63,watch64
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  // {
  //   _id: 1005,
  //   title: "Journal",
  //   link: "/journal",
  // },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: w1,
    productName: "Women's Outfit",
    price: "4535.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "202",
    img: w4,
    productName: "Women's Outfit",
    price: "6220.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "203",
    img: w5,
    productName: "Women's Outfit",
    price: "4850.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "204",
    img: w6,
    productName: "Women's Outfit",
    price: "5300.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [

  {
    _id: 900,
    img: [kurta11,kurta12,kurta13,kurta14],
    productName: "Kurti",
    price: "4535.00",
    category: "Kurti",
    badge: true,
    des: "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look.",
  },
  {
    _id: 901,
    img: [kurta21,kurta22, kurta23],
    productName: "Designer Kurti",
    price: "5435.00",
    category: "Kurti",
    badge: true,
    des: "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look.",
  },
  {
    "_id": 902,
    "img": [kurta31,kurta32,kurta33],
    "productName": "Women's Kurti",
    "price": "4700.00",
    "category": "Kurti",
    "badge": true,
    "des": "This sleek outfit is perfect for formal events, offering a modern touch to classic elegance."
  },
  {
    "_id": 903,
    "img": [kurta41,kurta42,kurta43,kurta44],
    "productName": "Kurti",
    "price": "6220.00",
    "category": "Kurti",
    "badge": true,
    "des": "A comfortable and stylish option for a day out, blending fashion with ease of movement."
  },
  {
    "_id": 904,
    "img": [kurta51,kurta52,kurta53,kurta54],
    "productName": "Premium Kurti",
    "price": "4850.00",
    "category": "Kurti",
    "badge": true,
    "des": "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look."
  },
  {
    "_id": 905,
    "img": [kurta61,kurta62,kurta63,kurta64],
    "productName": "Kurti",
    "price": "5300.00",
    "category": "Kurti",
    "badge": true,
    "des": "A trendy ensemble designed to keep you looking chic while staying comfortable throughout the day."
  },
  {
    "_id": 906,
    "img": [handbag11,handbag12,handbag13],
    "productName": "Women's Handbag",
    "price": "6950.00",
    "category": "Handbag",
    "badge": true,
    "des": "Elevate your wardrobe with this bold and sophisticated design, perfect for evening occasions."
  },
  {
    "_id": 907,
    "img": [handbag21,handbag22,handbag23,handbag24],
    "productName": "Handbag",
    "price": "5900.00",
    "category": "Handbag",
    "badge": true,
    "des": "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look."
  },
  {
    "_id": 908,
    "img": [handbag31,handbag32,handbag33],
    "productName": "Women's Handbag",
    "price": "6650.00",
    "category": "Handbag",
    "badge": true,
    "des": "Tailored to perfection, this outfit brings out a sense of understated glamour and style."
  },
  {
    "_id": 909,
    "img": [handbag43,handbag42,handbag44,handbag45],
    "productName": "Women's Handbag",
    "price": "4999.00",
    "category": "Handbag",
    "badge": true,
    "des": "A delightful mix of textures and colors, designed to turn heads and make a statement."
  },
  {
    "_id": 910,
    "img": [handbag52,handbag51,handbag53],
    "productName": "Women's Handbag",
    "price": "4530.00",
    "category": "Handbag",
    "badge": true,
    "des": "Perfect for any occasion, this outfit pairs minimalism with a refined, sophisticated touch."
  },
  {
    "_id": 911,
    "img": [handbag61,handbag62,handbag63],
    "productName": "Handbag",
    "price": "7150.00",
    "category": "Handbag",
    "badge": true,
    "des": "Inspired by the latest runway trends, this outfit delivers a bold statement with its unique design."
  },
  {
    "_id": 912,
    "img": [earing11,earing13],
    "productName": "Women's Earing",
    "price": "6000.00",
    "category": "earing",
    "badge": true,
    "des": "This stunning outfit strikes the perfect balance between comfort and high fashion."
  },
  {
    "_id": 913,
    "img": [earing21,earing22,earing23],
    "productName": "Women's Earing",
    "price": "5500.00",
    "category": "earing",
    "badge": true,
    "des": "Designed for a flawless fit, this outfit highlights your silhouette with an elegant finish."
  },
  {
    "_id": 914,
    "img": [earing31,earing32,earing33],
    "productName": "Women's Earing",
    "price": "4700.00",
    "category": "earing",
    "badge": true,
    "des": "Featuring a mix of casual and refined elements, this outfit is perfect for any occasion."
  },
  {
    "_id": 915,
    "img": [earing41,earing42],
    "productName": "Women's Earing",
    "price": "5230.00",
    "category": "earing",
    "badge": true,
    "des": "An effortless blend of modern and classic styles, this outfit ensures a chic and timeless look."
  },
  {
    "_id": 916,
    "img": [earing51,earing52,earing53],
    "productName": "Women's Earing",
    "price": "4800.00",
    "category": "earing",
    "badge": true,
    "des": "A bold, modern design with attention to detail, making it the perfect addition to your wardrobe."
  },
  {
    "_id": 917,
    "img": [earing61,earing62,earing63,earing64],
    "productName": "Women's Earing",
    "price": "6750.00",
    "category": "earing",
    "badge": true,
    "des": "An avant-garde piece for the fashion-forward woman, designed to leave a lasting impression."
  },
  {
    "_id": 918,
    "img": [dress11,dress12,dress13],
    "productName": "Women's Dress",
    "price": "7400.00",
    "category": "dress",
    "badge": true,
    "des": "A sophisticated outfit for formal events, blending luxury fabrics with timeless design."
  },
  {
    "_id": 919,
    "img": [dress21,dress22,dress23],
    "productName": "Women's Dress",
    "price": "4520.00",
    "category": "dress",
    "badge": true,
    "des": "Elegant and comfortable, this outfit is perfect for everyday wear with a touch of class."
  },
  {
    "_id": 920,
    "img": [dress31,dress32,dress33],
    "productName": "Women's Dress",
    "price": "7200.00",
    "category": "dress",
    "badge": true,
    "des": "A luxurious ensemble featuring intricate detailing and the highest quality materials."
  },
  {
    "_id": 921,
    "img": [dress41,dress42,dress43],
    "productName": "Women's Dress",
    "price": "6500.00",
    "category": "dress",
    "badge": true,
    "des": "Bold and stylish, this outfit offers a fresh take on classic design elements."
  },
  {
    "_id": 922,
    "img": [dress51,dress52,dress53],
    "productName": "Women's Dress",
    "price": "5870.00",
    "category": "dress",
    "badge": true,
    "des": "This outfit is designed to make you feel empowered with its elegant and modern design."
  },
  {
    "_id": 923,
    "img": [dress61,dress62,dress63],
    "productName": "Women's Dress",
    "price": "5950.00",
    "category": "dress",
    "badge": true,
    "des": "An ideal choice for the modern woman, this outfit combines style with functionality."
  },
  {
    "_id": 924,
    "img": [wallet11,wallet12,wallet13],
    "productName": "Wallets",
    "price": "5300.00",
    "category": "wallet",
    "badge": true,
    "des": "Perfect for both day and evening wear, this outfit delivers a polished and professional look."
  },
  {
    "_id": 925,
    "img": [wallet21,wallet22,wallet23],
    "productName": "Women's Wallets",
    "price": "4750.00",
    "category": "wallet",
    "badge": true,
    "des": "A timeless outfit designed to bring out your confidence with its elegant and bold style."
  },
  {
    "_id": 926,
    "img": [wallet31,wallet32,wallet33],
    "productName": " Wallets",
    "price": "6750.00",
    "category": "Wallets",
    "badge": true,
    "des": "A refined piece that showcases your sense of fashion with its sophisticated silhouette."
  },
  {
    "_id": 927,
    "img": [wallet41,wallet42,wallet43,wallet44],
    "productName": " Wallets",
    "price": "6750.00",
    "category": "Wallets",
    "badge": true,
    "des": "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look."
  },
  {
    "_id": 928,
    "img": [wallet51,wallet52,wallet53],
    "productName": "Women's Wallets",
    "price": "4200.00",
    "category": "Wallets",
    "badge": true,
    "des": "A stunning floral-inspired accessory that adds a touch of elegance to any outfit."
  },
  {
    "_id": 929,
    "img": [wallet61,wallet62,wallet63],
    "productName": "Women's Wallets",
    "price": "5000.00",
    "category": "Wallets",
    "badge": true,
    "des": "This accessory combines modern design with classic floral motifs for a chic look."
  },
  {
    "_id": 930,
    "img": [sandal11,sandal12,sandal13],
    "productName": "Women's Sandals",
    "price": "6100.00",
    "category": "sandal",
    "badge": true,
    "des": "Crafted with intricate detailing, this accessory is perfect for special occasions."
  },
  {
    "_id": 931,
    "img": [sandal21,sandal22,sandal23],
    "productName": "Sandals",
    "price": "4500.00",
    "category": "sandal",
    "badge": true,
    "des": "An elegant accessory that complements both casual and formal wear with ease."
  },
  {
    "_id": 932,
    "img": [sandal31,sandal32,sandal33],
    "productName": "Women's Sandals",
    "price": "5200.00",
    "category": "sandal",
    "badge": true,
    "des": "A versatile and trendy accessory designed to enhance any look with floral elegance."
  },
  {
    "_id": 933,
    "img": [sandal41,sandal42,sandal44,sandal43],
    "productName": "Women's Sandals",
    "price": "4850.00",
    "category": "sandal",
    "badge": true,
    "des": "This delicate accessory features a timeless design that complements every style."
  },
  {
    "_id": 934,
    "img": [sandal51,sandal52,sandal53],
    "productName": "Sandals",
    "price": "6100.00",
    "category": "sandal",
    "badge": true,
    "des": "A luxurious accessory made with premium materials to elevate your ensemble."
  },
  {
    "_id": 935,
    "img": [sandal61,sandal62,sandal63,sandal64],
    "productName": "Women's Sandals",
    "price": "5900.00",
    "category": "sandal",
    "badge": true,
    "des": "Add a touch of sophistication with this finely crafted floral accessory."
  },
  {
    "_id": 936,
    "img": [neckless11,neckless12,neckless13],
    "productName": "Designer Neckless",
    "price": "4800.00",
    "category": "neckless",
    "badge": true,
    "des": "A perfect blend of style and grace, designed to enhance your overall appearance."
  },
  {
    "_id": 937,
    "img": [neckless21,neckless22,neckless23,neckless24],
    "productName": "Neckless",
    "price": "6300.00",
    "category": "neckless",
    "badge": true,
    "des": "This eye-catching accessory makes a statement with its unique floral design."
  },
  {
    "_id": 938,
    "img": [neckless31,neckless32,neckless33,neckless34],
    "productName": "Women's Neckless",
    "price": "4500.00",
    "category": "neckless",
    "badge": true,
    "des": "A beautiful floral accessory that brings a fresh and vibrant look to any outfit."
  },
  {
    "_id": 939,
    "img": [neckless41,neckless42,neckless43,neckless44],
    "productName": "Women's Neckless",
    "price": "5400.00",
    "category": "neckless",
    "badge": true,
    "des": "A stunning accessory that combines delicate design with floral-inspired elegance."
  },
  {
    "_id": 940,
    "img": [neckless51,neckless52,neckless53,neckless54],
    "productName": "Neckless",
    "price": "4900.00",
    "category": "neckless",
    "badge": true,
    "des": "A charming accessory featuring intricate floral patterns, perfect for any occasion."
  },
  {
    "_id": 941,
    "img": [neckless61,neckless62,neckless63,neckless64],
    "productName": "Women's Neckless",
    "price": "6000.00",
    "category": "neckless",
    "badge": true,
    "des": "This high-end accessory brings a luxurious touch to both casual and formal outfits."
  },
  {
    "_id": 942,
    "img": [coset11,coset12,coset13],
    "productName": "Women's CO set's",
    "price": "4400.00",
    "category": "coset",
    "badge": true,
    "des": "Designed for those who love elegant floral designs, this accessory is a must-have."
  },
  {
    "_id": 943,
    "img": [coset21,coset22,coset23,coset24],
    "productName": "CO set's",
    "price": "5800.00",
    "category": "coset",
    "badge": true,
    "des": "An exquisite accessory that adds a sophisticated floral element to your look."
  },
  {
    "_id": 944,
    "img": [coset31,coset32,coset33,coset34],
    "productName": "Women's CO set's",
    "price": "4600.00",
    "category": "coset",
    "badge": true,
    "des": "A delicately designed floral accessory, perfect for adding charm to any ensemble."
  },
  {
    "_id": 945,
    "img": [coset41,coset42,coset43],
    "productName": "Women's CO set's",
    "price": "6200.00",
    "category": "coset",
    "badge": true,
    "des": "Make a bold fashion statement with this luxurious and trendy floral accessory."
  },
  {
    "_id": 946,
    "img": [coset51,coset52,coset53],
    "productName": "Women's CO set's",
    "price": "4700.00",
    "category": "coset",
    "badge": true,
    "des": "This beautifully crafted accessory enhances your outfit with its subtle floral details."
  },
  {
    "_id": 947,
    "img": [coset61,coset62,coset63,coset63,coset64],
    "productName": "Women's Accessory",
    "price": "5100.00",
    "category": "accessory",
    "badge": true,
    "des": "A timeless accessory that features a delicate floral motif for a touch of grace."
  },
  {
    "_id": 948,
    "img": [hoodie11,hoodie12,hoodie13],
    "productName": "Women's Hoodie",
    "price": "5400.00",
    "category": "Hoodie",
    "badge": true,
    "des": "A chic and modern floral accessory that adds a unique flair to your wardrobe."
  },
  {
    "_id": 949,
    "img": [hoodie21,hoodie22,hoodie23],
    "productName": "Women's Hoodie",
    "price": "6000.00",
    "category": "Hoodie",
    "badge": true,
    "des": "A standout piece that perfectly complements a range of outfits with floral elegance."
  },
  {
    "_id": 950,
    "img": [hoodie31,handbag32,handbag33],
    "productName": "Women's Hoodie",
    "price": "6100.00",
    "category": "Hoodie",
    "badge": true,
    "des": "Crafted with intricate detailing, this accessory is perfect for special occasions."
  },
  {
    "_id": 951,
    "img": [hoodie41,hoodie42,hoodie43,hoodie44],
    "productName": "Hoodie",
    "price": "4500.00",
    "category": "Hoodie",
    "badge": true,
    "des": "An elegant accessory that complements both casual and formal wear with ease."
  },
  {
    "_id": 952,
    "img": [hoodie51,handbag52,handbag53],
    "productName": "Women's Hoodie",
    "price": "5200.00",
    "category": "Hoodie",
    "badge": true,
    "des": "A versatile and trendy accessory designed to enhance any look with floral elegance."
  },
  {
    "_id": 953,
    "img": [hoodie61,hoodie62,handbag63],
    "productName": "Women's Hoodie",
    "price": "4850.00",
    "category": "Hoodie",
    "badge": true,
    "des": "This delicate accessory features a timeless design that complements every style."
  },
  {
    "_id": 954,
    "img": [jeans11,jeans12,jeans13,jeans14],
    "productName": "Jeans",
    "price": "6100.00",
    "category": "Jeans",
    "badge": true,
    "des": "A luxurious accessory made with premium materials to elevate your ensemble."
  },
  {
    "_id": 955,
    "img": [jeans21,jeans22,jeans23,jeans24],
    "productName": "Women's Jeans",
    "price": "5900.00",
    "category": "Jeans",
    "badge": true,
    "des": "Add a touch of sophistication with this finely crafted floral accessory."
  },
  {
    "_id": 956,
    "img": [jeans31,jeans32,jeans33,jeans34],
    "productName": "Designer Jeans",
    "price": "4800.00",
    "category": "Jeans",
    "badge": true,
    "des": "A perfect blend of style and grace, designed to enhance your overall appearance."
  },
  {
    "_id": 957,
    "img": [jeans41,jeans42,jeans43,jeans44],
    "productName": "Jeans",
    "price": "6300.00",
    "category": "Jeans",
    "badge": true,
    "des": "This eye-catching accessory makes a statement with its unique floral design."
  },
  {
    "_id": 958,
    "img": [jeans51,jeans52,jeans53,jeans54],
    "productName": "Women's Jeans",
    "price": "4500.00",
    "category": "Jeans",
    "badge": true,
    "des": "A beautiful floral accessory that brings a fresh and vibrant look to any outfit."
  },
  {
    "_id": 959,
    "img": [jeans61,jeans62,jeans63,jeans64],
    "productName": "Women's Jeans",
    "price": "5400.00",
    "category": "Jeans",
    "badge": true,
    "des": "A stunning accessory that combines delicate design with floral-inspired elegance."
  },
  {
    _id: 960,
    img: [sun11,sun12,sun13,sun14],
    productName: "Sunglasses",
    price: "5435.00",
    category: "Sunglasses",
    badge: true,
    des: "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look.",
  },
  {
    "_id": 961,
    "img": [sun21,sun22,sun23],
    "productName": "Women's Sunglasses",
    "price": "4700.00",
    "category": "Sunglasses",
    "badge": true,
    "des": "This sleek outfit is perfect for formal events, offering a modern touch to classic elegance."
  },
  {
    "_id": 962,
    "img": [sun31,sun32,sun33],
    "productName": "Sunglasses",
    "price": "6220.00",
    "category": "Sunglasses",
    "badge": true,
    "des": "A comfortable and stylish option for a day out, blending fashion with ease of movement."
  },
  {
    "_id": 964,
    "img": [sun41,sun42,sun43,sun44],
    "productName": "Premium Sunglasses",
    "price": "4850.00",
    "category": "Sunglasses",
    "badge": true,
    "des": "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look."
  },
  {
    "_id": 965,
    "img": [sun51,sun52,sun53],
    "productName": "Sunglasses",
    "price": "5300.00",
    "category": "Sunglasses",
    "badge": true,
    "des": "A trendy ensemble designed to keep you looking chic while staying comfortable throughout the day."
  },
  {
    "_id": 966,
    "img": [sun61,sun62,sun63,sun64],
    "productName": "Women's Sunglasses",
    "price": "6950.00",
    "category": "Sunglasses",
    "badge": true,
    "des": "Elevate your wardrobe with this bold and sophisticated design, perfect for evening occasions."
  },
  {
    "_id": 967,
    "img": [watch11,watch12,watch13],
    "productName": "Watch",
    "price": "5900.00",
    "category": "Watch",
    "badge": true,
    "des": "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look."
  },
  {
    "_id": 968,
    "img": [watch31,watch32,watch33],
    "productName": "Women's Watch",
    "price": "6650.00",
    "category": "Watch",
    "badge": true,
    "des": "Tailored to perfection, this outfit brings out a sense of understated glamour and style."
  },
  {
    "_id": 969,
    "img": [watch21,watch22,watch23,watch24],
    "productName": "Women's Watch",
    "price": "4999.00",
    "category": "Watch",
    "badge": true,
    "des": "A delightful mix of textures and colors, designed to turn heads and make a statement."
  },
  {
    "_id": 971,
    "img": [watch41,watch42,watch43,watch44],
    "productName": "Women's Watch",
    "price": "4530.00",
    "category": "Watch",
    "badge": true,
    "des": "Perfect for any occasion, this outfit pairs minimalism with a refined, sophisticated touch."
  },
  {
    "_id": 972,
    "img": [watch51,watch52,watch53,watch54],
    "productName": "Watch",
    "price": "7150.00",
    "category": "Watch",
    "badge": true,
    "des": "Inspired by the latest runway trends, this outfit delivers a bold statement with its unique design."
  },
  {
    "_id": 973,
    "img": [watch61,watch62,watch63,watch64],
    "productName": "Women's Watch",
    "price": "6000.00",
    "category": "Watch",
    "badge": true,
    "des": "This stunning outfit strikes the perfect balance between comfort and high fashion."
  },
  {
    "_id": 974,
    "img": [tp11,tp12,tp13],
    "productName": "Women's Track pants",
    "price": "5500.00",
    "category": "Track pants",
    "badge": true,
    "des": "Designed for a flawless fit, this outfit highlights your silhouette with an elegant finish."
  },
  {
    "_id": 975,
    "img": [tp21,tp22,tp23],
    "productName": "Women's Track pants",
    "price": "4700.00",
    "category": "Track pants",
    "badge": true,
    "des": "Featuring a mix of casual and refined elements, this outfit is perfect for any occasion."
  },
  {
    "_id": 976,
    "img": [tp31,tp32,tp33],
    "productName": "Women's Track pants",
    "price": "5230.00",
    "category": "Track pants",
    "badge": true,
    "des": "An effortless blend of modern and classic styles, this outfit ensures a chic and timeless look."
  },
  {
    "_id": 977,
    "img": [tp41,tp42,tp43,tp44],
    "productName": "Women's Track pants",
    "price": "4800.00",
    "category": "Track pants",
    "badge": true,
    "des": "A bold, modern design with attention to detail, making it the perfect addition to your wardrobe."
  },
  {
    "_id": 978,
    "img": [tp51,tp52,tp53,tp54],
    "productName": "Women's Track pants",
    "price": "6750.00",
    "category": "Track pants",
    "badge": true,
    "des": "An avant-garde piece for the fashion-forward woman, designed to leave a lasting impression."
  },
  {
    "_id": 979,
    "img": [tp61,tp62,tp63,tp64],
    "productName": "Women's Track pants",
    "price": "7400.00",
    "category": "Track pants",
    "badge": true,
    "des": "A sophisticated outfit for formal events, blending luxury fabrics with timeless design."
  },
  {
    "_id": 980,
    "img": [ss11,ss12,ss13,ss14],
    "productName": "Women's Sweatshirt",
    "price": "4520.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "Elegant and comfortable, this outfit is perfect for everyday wear with a touch of class."
  },
  {
    "_id": 981,
    "img": [ss21,ss22,ss23,ss24],
    "productName": "Women's Sweatshirt",
    "price": "7200.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "A luxurious ensemble featuring intricate detailing and the highest quality materials."
  },
  {
    "_id": 982,
    "img": [ss31,ss32,ss33],
    "productName": "Women's Sweatshirt",
    "price": "6500.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "Bold and stylish, this outfit offers a fresh take on classic design elements."
  },
  {
    "_id": 983,
    "img": [ss41,ss42,ss43],
    "productName": "Women's Sweatshirt",
    "price": "5870.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "This outfit is designed to make you feel empowered with its elegant and modern design."
  },
  {
    "_id": 984,
    "img": [ss51,ss52,ss53,ss54],
    "productName": "Women's Sweatshirt",
    "price": "5950.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "An ideal choice for the modern woman, this outfit combines style with functionality."
  },
  {
    "_id": 985,
    "img": [ss61,ss62,ss63,ss64],
    "productName": "Sweatshirt",
    "price": "5300.00",
    "category": "Sweatshirt",
    "badge": true,
    "des": "Perfect for both day and evening wear, this outfit delivers a polished and professional look."
  },
  
 
  {
    _id: 1001,
    img: w1,
    productName: "Women's Outfit",
    price: "4535.00",
    category: "outfit",
    badge: true,
    des: "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look.",
  },
  {
    _id: 1002,
    img: w2,
    productName: "Women's Outfit",
    price: "5880.00", 
    category: "outfit",
    badge: true,
    des: "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look.",
  },
  {
    "_id": 1003,
    "img": w3,
    "productName": "Women's Outfit",
    "price": "4700.00",
    "category": "outfit",
    "badge": true,
    "des": "This sleek outfit is perfect for formal events, offering a modern touch to classic elegance."
  },
  {
    "_id": 1004,
    "img": w4,
    "productName": "Women's Outfit",
    "price": "6220.00",
    "category": "outfit",
    "badge": true,
    "des": "A comfortable and stylish option for a day out, blending fashion with ease of movement."
  },
  {
    "_id": 1005,
    "img": w5,
    "productName": "Women's Outfit",
    "price": "4850.00",
    "category": "outfit",
    "badge": true,
    "des": "Crafted with care, this outfit combines luxury fabrics with a tailored fit for a flattering look."
  },
  {
    "_id": 1006,
    "img": w6,
    "productName": "Women's Outfit",
    "price": "5300.00",
    "category": "outfit",
    "badge": true,
    "des": "A trendy ensemble designed to keep you looking chic while staying comfortable throughout the day."
  },
  {
    "_id": 1007,
    "img": w7,
    "productName": "Women's Outfit",
    "price": "6950.00",
    "category": "outfit",
    "badge": true,
    "des": "Elevate your wardrobe with this bold and sophisticated design, perfect for evening occasions."
  },
  {
    "_id": 1008,
    "img": w8,
    "productName": "Women's Outfit",
    "price": "5900.00",
    "category": "outfit",
    "badge": true,
    "des": "A versatile outfit that effortlessly transitions from casual daywear to an elegant evening look."
  },
  {
    "_id": 1009,
    "img": w9,
    "productName": "Women's Outfit",
    "price": "6650.00",
    "category": "outfit",
    "badge": true,
    "des": "Tailored to perfection, this outfit brings out a sense of understated glamour and style."
  },
  {
    "_id": 1010,
    "img": w10,
    "productName": "Women's Outfit",
    "price": "4999.00",
    "category": "outfit",
    "badge": true,
    "des": "A delightful mix of textures and colors, designed to turn heads and make a statement."
  },
  {
    "_id": 1011,
    "img": w19,
    "productName": "Women's Outfit",
    "price": "4530.00",
    "category": "outfit",
    "badge": true,
    "des": "Perfect for any occasion, this outfit pairs minimalism with a refined, sophisticated touch."
  },
  {
    "_id": 1012,
    "img": w12,
    "productName": "Women's Outfit",
    "price": "7150.00",
    "category": "outfit",
    "badge": true,
    "des": "Inspired by the latest runway trends, this outfit delivers a bold statement with its unique design."
  },
  {
    "_id": 1013,
    "img": w13,
    "productName": "Women's Outfit",
    "price": "6000.00",
    "category": "outfit",
    "badge": true,
    "des": "This stunning outfit strikes the perfect balance between comfort and high fashion."
  },
  {
    "_id": 1014,
    "img": w14,
    "productName": "Women's Outfit",
    "price": "5500.00",
    "category": "outfit",
    "badge": true,
    "des": "Designed for a flawless fit, this outfit highlights your silhouette with an elegant finish."
  },
  {
    "_id": 1015,
    "img": w15,
    "productName": "Women's Outfit",
    "price": "4700.00",
    "category": "outfit",
    "badge": true,
    "des": "Featuring a mix of casual and refined elements, this outfit is perfect for any occasion."
  },
  {
    "_id": 1016,
    "img": w16,
    "productName": "Women's Outfit",
    "price": "5230.00",
    "category": "outfit",
    "badge": true,
    "des": "An effortless blend of modern and classic styles, this outfit ensures a chic and timeless look."
  },
  {
    "_id": 1017,
    "img": w17,
    "productName": "Women's Outfit",
    "price": "4800.00",
    "category": "outfit",
    "badge": true,
    "des": "A bold, modern design with attention to detail, making it the perfect addition to your wardrobe."
  },
  {
    "_id": 1018,
    "img": w18,
    "productName": "Women's Outfit",
    "price": "6750.00",
    "category": "outfit",
    "badge": true,
    "des": "An avant-garde piece for the fashion-forward woman, designed to leave a lasting impression."
  },
  {
    "_id": 1019,
    "img": w19,
    "productName": "Women's Outfit",
    "price": "7400.00",
    "category": "outfit",
    "badge": true,
    "des": "A sophisticated outfit for formal events, blending luxury fabrics with timeless design."
  },
  {
    "_id": 1020,
    "img": w20,
    "productName": "Women's Outfit",
    "price": "4520.00",
    "category": "outfit",
    "badge": true,
    "des": "Elegant and comfortable, this outfit is perfect for everyday wear with a touch of class."
  },
  {
    "_id": 1021,
    "img": w21,
    "productName": "Women's Outfit",
    "price": "7200.00",
    "category": "outfit",
    "badge": true,
    "des": "A luxurious ensemble featuring intricate detailing and the highest quality materials."
  },
  {
    "_id": 1022,
    "img": w22,
    "productName": "Women's Outfit",
    "price": "6500.00",
    "category": "outfit",
    "badge": true,
    "des": "Bold and stylish, this outfit offers a fresh take on classic design elements."
  },
  {
    "_id": 1023,
    "img": w23,
    "productName": "Women's Outfit",
    "price": "5870.00",
    "category": "outfit",
    "badge": true,
    "des": "This outfit is designed to make you feel empowered with its elegant and modern design."
  },
  {
    "_id": 1024,
    "img": w24,
    "productName": "Women's Outfit",
    "price": "5950.00",
    "category": "outfit",
    "badge": true,
    "des": "An ideal choice for the modern woman, this outfit combines style with functionality."
  },
  {
    "_id": 1025,
    "img": w25,
    "productName": "Women's Outfit",
    "price": "5300.00",
    "category": "outfit",
    "badge": true,
    "des": "Perfect for both day and evening wear, this outfit delivers a polished and professional look."
  },
  {
    "_id": 1026,
    "img": w26,
    "productName": "Women's Outfit",
    "price": "4750.00",
    "category": "outfit",
    "badge": true,
    "des": "A timeless outfit designed to bring out your confidence with its elegant and bold style."
  },
  {
    "_id": 1027,
    "img": w27,
    "productName": "Women's Outfit",
    "price": "6750.00",
    "category": "outfit",
    "badge": true,
    "des": "A refined piece that showcases your sense of fashion with its sophisticated silhouette."
  },
  {
    "_id": 1028,
    "img": a1,
    "productName": "Women's Accessory",
    "price": "4200.00",
    "category": "accessory",
    "badge": true,
    "des": "A stunning floral-inspired accessory that adds a touch of elegance to any outfit."
  },
  {
    "_id": 1029,
    "img": a2,
    "productName": "Women's Accessory",
    "price": "5000.00",
    "category": "accessory",
    "badge": true,
    "des": "This accessory combines modern design with classic floral motifs for a chic look."
  },
  {
    "_id": 1030,
    "img": a3,
    "productName": "Women's Accessory",
    "price": "6100.00",
    "category": "accessory",
    "badge": true,
    "des": "Crafted with intricate detailing, this accessory is perfect for special occasions."
  },
  {
    "_id": 1031,
    "img": a4,
    "productName": "Women's Accessory",
    "price": "4500.00",
    "category": "accessory",
    "badge": true,
    "des": "An elegant accessory that complements both casual and formal wear with ease."
  },
  {
    "_id": 1032,
    "img": a5,
    "productName": "Women's Accessory",
    "price": "5200.00",
    "category": "accessory",
    "badge": true,
    "des": "A versatile and trendy accessory designed to enhance any look with floral elegance."
  },
  {
    "_id": 1033,
    "img": a6,
    "productName": "Women's Accessory",
    "price": "4850.00",
    "category": "accessory",
    "badge": true,
    "des": "This delicate accessory features a timeless design that complements every style."
  },
  {
    "_id": 1034,
    "img": a7,
    "productName": "Women's Accessory",
    "price": "6100.00",
    "category": "accessory",
    "badge": true,
    "des": "A luxurious accessory made with premium materials to elevate your ensemble."
  },
  {
    "_id": 1035,
    "img": a8,
    "productName": "Women's Accessory",
    "price": "5900.00",
    "category": "accessory",
    "badge": true,
    "des": "Add a touch of sophistication with this finely crafted floral accessory."
  },
  {
    "_id": 1036,
    "img": a9,
    "productName": "Women's Accessory",
    "price": "4800.00",
    "category": "accessory",
    "badge": true,
    "des": "A perfect blend of style and grace, designed to enhance your overall appearance."
  },
  {
    "_id": 1037,
    "img": a10,
    "productName": "Women's Accessory",
    "price": "6300.00",
    "category": "accessory",
    "badge": true,
    "des": "This eye-catching accessory makes a statement with its unique floral design."
  },
  {
    "_id": 1038,
    "img": a11,
    "productName": "Women's Accessory",
    "price": "4500.00",
    "category": "accessory",
    "badge": true,
    "des": "A beautiful floral accessory that brings a fresh and vibrant look to any outfit."
  },
  {
    "_id": 1039,
    "img": a12,
    "productName": "Women's Accessory",
    "price": "5400.00",
    "category": "accessory",
    "badge": true,
    "des": "A stunning accessory that combines delicate design with floral-inspired elegance."
  },
  {
    "_id": 1040,
    "img": a13,
    "productName": "Women's Accessory",
    "price": "4900.00",
    "category": "accessory",
    "badge": true,
    "des": "A charming accessory featuring intricate floral patterns, perfect for any occasion."
  },
  {
    "_id": 1041,
    "img": a14,
    "productName": "Women's Accessory",
    "price": "6000.00",
    "category": "accessory",
    "badge": true,
    "des": "This high-end accessory brings a luxurious touch to both casual and formal outfits."
  },
  {
    "_id": 1042,
    "img": a15,
    "productName": "Women's Accessory",
    "price": "4400.00",
    "category": "accessory",
    "badge": true,
    "des": "Designed for those who love elegant floral designs, this accessory is a must-have."
  },
  {
    "_id": 1043,
    "img": a16,
    "productName": "Women's Accessory",
    "price": "5800.00",
    "category": "accessory",
    "badge": true,
    "des": "An exquisite accessory that adds a sophisticated floral element to your look."
  },
  {
    "_id": 1044,
    "img": a17,
    "productName": "Women's Accessory",
    "price": "4600.00",
    "category": "accessory",
    "badge": true,
    "des": "A delicately designed floral accessory, perfect for adding charm to any ensemble."
  },
  {
    "_id": 1045,
    "img": a18,
    "productName": "Women's Accessory",
    "price": "6200.00",
    "category": "accessory",
    "badge": true,
    "des": "Make a bold fashion statement with this luxurious and trendy floral accessory."
  },
  {
    "_id": 1046,
    "img": a19,
    "productName": "Women's Accessory",
    "price": "4700.00",
    "category": "accessory",
    "badge": true,
    "des": "This beautifully crafted accessory enhances your outfit with its subtle floral details."
  },
  {
    "_id": 1047,
    "img": a20,
    "productName": "Women's Accessory",
    "price": "5100.00",
    "category": "accessory",
    "badge": true,
    "des": "A timeless accessory that features a delicate floral motif for a touch of grace."
  },
  {
    "_id": 1048,
    "img": a21,
    "productName": "Women's Accessory",
    "price": "5400.00",
    "category": "accessory",
    "badge": true,
    "des": "A chic and modern floral accessory that adds a unique flair to your wardrobe."
  },
  {
    "_id": 1049,
    "img": a22,
    "productName": "Women's Accessory",
    "price": "6000.00",
    "category": "accessory",
    "badge": true,
    "des": "A standout piece that perfectly complements a range of outfits with floral elegance."
  }
];
// =================== PaginationItems End here =================
