import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const  Shipping= () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    // Check if location.state and location.state.data exist
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation(""); // or handle the fallback behavior if data is missing
    }
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Shipping Policy" prevLocation={prevLocation} />
      <div className="container py-4">
     <b> **Shipping Partners and Coverage**</b><ul>
      <li>
      - Collaborates with trusted shipping partners for efficient delivery.

      </li>
      <li>
  - Services extend across a wide geographic range, including remote areas.

      </li>
      <li>
      - Worldwide shipping is available.
      </li>
     </ul>
  <br />

<b>**Processing Time**</b><ul>
  <li>
  - Orders are processed promptly after placement.
    
  </li>
  <li>
  - Average delivery time: 5-7 business days.

  </li>
  <li>
  - Processing times may vary during peak seasons or promotions.

  </li>
</ul>
<br />


<b> **Shipping Options**</b>
<ul>
  <li>
  - Standard shipping: cost-effective and reliable.

  </li>
  <li>
  - Expedited services: available for faster delivery.

  </li>
  <li>
  - Shipping methods are chosen for safe product transit.

  </li>
</ul>
<br />

<b> **Estimated Delivery Times**</b>
<ul>
  <li>
  - Delivery times vary based on location, shipping method, and unforeseen circumstances.

  </li>
  <li>
  - Estimated times are shown during checkout for informed decision-making.

  </li>
</ul>
<br /> 


<b> **Order Tracking**</b>
<ul>
  <li>
  - Tracking number provided via email after dispatch.

  </li>
  <li>
  - Real-time tracking available for your package.

  </li>
</ul>
<br />


<b> **International Shipping**</b>
<ul>
  <li>
  - Offers tailored services for international customers.

  </li>
  <li>
  - Delivery times may vary due to customs and local regulations.

  </li>
  <li>
  - Blue horse follows international shipping guidelines for smooth delivery.

  </li>
</ul>

<br />
<b>**Shipping Costs**</b>
<ul>
  <li>
  - Competitive and fair shipping fees.
  </li>
  <li>
  - Costs are calculated based on order weight, shipping method, and delivery location.
  </li>
  <li>
  - Shipping fees are displayed during checkout.
  </li>
</ul>
<br />
<b>**Address Accuracy**</b>
<ul>
  <li>
  - Accurate shipping information is essential for smooth delivery.
  </li>
  <li>
  - Blue horse is not responsible for delays due to incorrect addresses.
  </li>
  <li>
  - Double-check address details before confirming the order.
  </li>
</ul>
<br />
<b>**Shipping Updates and Notifications**</b>
<ul>
  <li>
  - Order confirmation, shipping confirmation, and delivery updates are sent via email.
  </li>
  <li>
  - Tracking information is provided for each step of the process.
  </li>
</ul>
      </div>
    </div>
  );
};

export default Shipping;
