import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const  Terms= () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    // Check if location.state and location.state.data exist
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation(""); // or handle the fallback behavior if data is missing
    }
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Terms and Condition" prevLocation={prevLocation} />
      <div className='container py-3'>

  <h3 className='text-center'>TERMS OF SERVICE</h3>
  <hr />
  
  
  <p>
    <h5>Last updated on: 05/10/2024</h5>
    <br />
    <br />
    <b>1. Acceptance of Terms</b> <br />
    Welcome to https://shop.bluehorses.in! By accessing or using our website, you agree to comply with and be bound by the following Terms of Service. Please read them carefully. If you disagree, do not use our services. By using our website, you affirm that you are at least 18 years old and have the legal capacity to enter into these terms. If you are using the site on behalf of a company or organization, you represent that you have the authority to bind that entity. You can review the current version of the Terms&condition at any time at https://shop.bluehorses.in/terms-condition.
    <br />
    <br />
    <b>2. Changes to Terms
    </b> <br />
    We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting on our site. Your continued use of the site constitutes acceptance of the modified terms.
    <br /><br />
    <b>3. User Accounts</b>
    - Account Creation: To make a purchase, you may need to create an account. You agree to provide accurate, current, and complete information during registration.
<br />
- Account Security: You are responsible for maintaining the confidentiality of your account information and for all activities under your account.
<br /> <br />
<b>4. Product Information</b><br />
We strive to provide accurate descriptions and pricing for all products. However, we do not warrant that product information is complete, reliable, or error-free. Prices and availability are subject to change without notice.
<br /><br />
<b>5. Order Acceptance and Payment</b>
<br />- Order Confirmation: All orders are subject to acceptance by us. We will send you an order confirmation via email.
<br />- Payment: Payment must be made in full at the time of purchase. We accept various payment methods, which will be listed at checkout.
<br /><br />
<b>6. Shipping and Delivery</b>
<br />
- Shipping times may vary based on location and shipping method. <br />
- We are not responsible for delays caused by shipping carriers or other factors beyond our control.
<br /> <br />
<b>7. Returns and Refunds</b>
- You may return products within 7 days of receipt for a full refund, provided they are in original condition.
<br />
You can review the current Returns and Refunds policy at any time at https://shop.bluehorses.in/return-policy.
<br /> <br />
<b>8. User Conduct</b>
<br />
You agree not to engage in any of the following prohibited activities: <br />
- Violating any applicable laws or regulations.<br />
- Impersonating any person or entity or misrepresenting your affiliation with a person or entity.<br />
- Interfering with or disrupting the security, integrity, or performance of our website.<br />
<br /><br />
<b>9. Intellectual Property</b>
<br />
All content on our website, including text, graphics, logos, and images, is the property of the https://shop.bluehorses.in and is protected by intellectual property laws.
<br /><br />
<b>10. Limitation of Liability</b><br />
To the fullest extent permitted by law, https://shop.bluehorses.in shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our website or products.
<br /><br />
<b>11. Indemnification</b>
<br />
You agree to indemnify and hold harmless https://shop.bluehorses.in, its affiliates, and their respective employees and agents from any claims, losses, or damages arising out of your use of the website or violation of these terms.
<br /> <br />
<b>12. Governing Law</b>
<br />
These Terms of Service shall be governed by and construed by the laws of Uttar Pradesh/India. Any disputes arising from these terms shall be resolved in Noida's courts.
<br /><br />
<b>13. Contact Information</b><br />
For any questions about these Terms of Service, please contact us at gauravsingh.bluehorse@gmail.com.
<br /> <br />
<b>Important Considerations:</b>
<br />
<br />- Customization: Replace placeholders https://shop.bluehorses.in, +91 8719080262, Noida/Uttar Pradesh/India. 
<br />- Legal Review: It’s advisable to consult with a legal professional to ensure your terms are compliant with local laws and regulations.
<br />- Clarity: Use clear language to ensure customers easily understand their rights and responsibilities.

<br />
<br />

Feel free to ask if you need additional sections or specific clauses!


  </p>





    </div>
    </div>
  );
};

export default Terms;
