import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const  PrivacyPolicy= () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    // Check if location.state and location.state.data exist
    if (location.state && location.state.data) {
      setPrevLocation(location.state.data);
    } else {
      setPrevLocation(""); // or handle the fallback behavior if data is missing
    }
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Privacy Policy" prevLocation={prevLocation} />
      <div className='container py-3'>
      {/* <h2 className='text-center my-3'>Privacy Policy</h2> */}
      <b> **Collection of Personal Information**</b>
      <ul>
        <li>
        - Personal information includes details like name, email address, phone number, payment details, purchase information, and shipping address.
        </li>
        <li>
        - Information is collected when creating an account or when processing returns, exchanges, or refunds for Blue horse products.
        </li>
        <li>
        - Personal data is used to enhance customer satisfaction and tailor services to meet user needs.
        </li>
      </ul>
<br />

<b> **Automatic Information, Cookies, and Tracking**</b>
<ul>
  <li>
  - Blue horse and third-party providers use cookies, web beacons, and tracking technologies to gather data on site usage, such as browser type, ISP, device type, IP address, page views, and more.
  </li>
  <li>
  - Information is collected to assess the success of advertising campaigns and improve the site.
  </li>
  <li>
  - Cookies help personalize user experience by remembering preferences and improving web application interactions.
  </li>
  <li>
  - Third-party cookies (e.g., from Google) are used, with usage governed by both this policy and the third-party’s privacy policy.
  </li>
</ul>
<br />

<b> **How Blue horse Uses Personal Information**</b>
<ul>
  <li>
  - To provide services, including account registration, identity verification, and service access.
  </li>
  <li>
  - For customer support and responding to inquiries.
  </li>
  <li>
  - For internal record-keeping and operational purposes.
  </li>
  <li>
  - To manage shipping, delivery, and initiate product returns, exchanges, and refunds.
  </li>
  <li>
  - To process billing and payments in collaboration with third-party payment gateways.
  </li>
  <li>
  - To improve the website by analyzing user activity, such as search inputs, and identifying areas for enhancement.

  </li>
</ul>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
