import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  a1,a2,a10,a12,a14,a16, a15, a11,a21,a22,a13,a17,a19,handbag11,handbag12,handbag13,handbag21,handbag22,handbag23,handbag24,
  neckless31,neckless32,neckless33,neckless34,jeans21,jeans22,jeans23,jeans24,jeans31,jeans32,jeans33,jeans34
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={[jeans31,jeans32,jeans33,jeans34]}
          productName="Designer Jeans"
          price="4800.00"
          category= "Jeans"
          badge={true}
          des="A delicately designed floral accessory, perfect for adding charm to any ensemble."
        />
        <Product
          _id="1012"
          img={ [handbag21,handbag22,handbag23,handbag24]}
          productName="Women's Handbag"
          price="5900.00"
          category= "Handbag"

          badge={false}
          des="Make a bold fashion statement with this luxurious and trendy floral accessory."
        />
        <Product
          _id="1013"
          img={[neckless31,neckless32,neckless33,neckless34]}
          productName="Neckless"
          price="4500.00"
          category= "Neckless"

          badge={true}
          des="This beautifully crafted accessory enhances your outfit with its subtle floral details."
        />
        <Product
          _id="1014"
          img={[jeans21,jeans22,jeans23,jeans24]}
          productName="Women's Jeans"
          price="5900.00"
          category= "Jeans"

          badge={false}
          des="A timeless accessory that features a delicate floral motif for a touch of grace."
        />
      </div>
    </div>
  );
};

export default BestSellers;
