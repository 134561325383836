import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems } from "../../../constants"; // Assuming this contains the product data

const items = paginationItems; // Your products data


// Component to render current items
function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item._id} className="w-full">
            <Product
              _id={item._id}
              img={ item.img}
              productName={item.productName}
              price={item.price}
              color={item.color}
              badge={item.badge}
              des={item.des}
            />
          </div>
        ))}
    </>
  );
}

const Pagination = ({ itemsPerPage }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [filter, setFilter] = useState("all"); // State for filter

  // Function to filter the items based on the selected filter
  const filterItems = (items, filter) => {
    if (filter === "all") {
      return items; // Show all items
    }
    return items.filter((item) => item.category === filter); // Filter based on category
  };

  // Filtered items based on the selected category
  const filteredItems = filterItems(items, filter);

  // Pagination logic
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  // Handle page click event for pagination
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredItems.length;
    setItemOffset(newOffset);
    setItemStart(newOffset);
  };

  return (
    <div>
      {/* Filter Buttons */}
      <div className="flex gap-4 mb-4">
        <button
          onClick={() => {
            setFilter("outfit");
            setItemOffset(0); // Reset pagination when filter changes
          }}
          className={`px-4 py-2 rounded ${
            filter === "outfit" ? "bg-primeColor text-white" : "bg-gray-200 text-black"
          } duration-300`}
        >
          Outfits
        </button>
        <button
          onClick={() => {
            setFilter("accessory");
            setItemOffset(0); // Reset pagination when filter changes
          }}
          className={`px-4 py-2 rounded ${
            filter === "accessory" ? "bg-primeColor text-white" : "bg-gray-200 text-black"
          } duration-300`}
        >
          Accessories
        </button>
        <button
          onClick={() => {
            setFilter("all");
            setItemOffset(0); // Reset pagination when filter changes
          }}
          className={`px-4 py-2 rounded ${
            filter === "all" ? "bg-primeColor text-white" : "bg-gray-200 text-black"
          } duration-300`}
        >
          All
        </button>
      </div>

      {/* Paginated Items */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>

      {/* Pagination Controls */}
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex text-base font-semibold font-titleFont py-10"
          activeClassName="bg-black text-white"
        />
        <p className="text-base font-normal text-lightText">
          Products from {itemStart === 0 ? 1 : itemStart} to {endOffset > filteredItems.length ? filteredItems.length : endOffset} of {filteredItems.length}
        </p>
      </div>
    </div>
  );
};

export default Pagination;
